<template>
    <div class="newEvacuatore" style="height:100%;">
        <v-layout justify-center align-center style="height:100%;">
            <v-flex xs11 md8 lg5>
                <v-toolbar color="secondary">
                    <v-toolbar-title class="white--text">
                        Nuovo Evacuatore
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                        <v-icon small>fas fa-times</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card outlined>
                    <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                        <v-container>
                            <v-layout column>
                                <Schede :id_stabilimento="id_stabilimento" @setIdScheda="setIdScheda" :actionScheda="true" class="mb-5"/>
                                <v-text-field autocomplete="off"
                                              label="Matricola"
                                              v-model="matricola"
                                              required
                                              :rules="matricolaRules"/>
                                <v-text-field autocomplete="off"
                                              label="Anno"
                                              type="number"
                                              min="1700"
                                              max="3000"
                                              v-model="anno"
                                              required
                                              :rules="annoRules"/>
                                <v-textarea autocomplete="off"
                                            label="Note"
                                            v-model="note"
                                            outlined
                                            auto-grow
                                            rows="1"/>
                                <v-select v-if="stabilimento && stabilimento.reparti"
                                          :items="stabilimento.reparti"
                                          v-model="reparto"
                                          item-text="nome"
                                          item-value="_id"
                                          label="Seleziona un reparto" />
                                <v-menu ref="menuDataRapporto_creazione"
                                        v-model="menuDataRapporto_creazione"
                                        :close-on-content-click="false"
                                        :return-value.sync="dataUltimoRapporto"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            autocomplete="off"
                                            v-model="dataUltimoRapporto"
                                            v-if="ruolo"
                                            label="Data Ultima Sostituzione Bombola"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dataUltimoRapporto"
                                        no-title
                                        scrollable
                                        locale="it"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menuDataRapporto_creazione = false">
                                            Chiudi
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menuDataRapporto_creazione.save(dataUltimoRapporto)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-layout>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-container grid-list-sm fluid>
                            <v-row>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-checkbox v-model="collegaPosa"
                                                label="Collega rapporto di Posa"
                                                color="primary"
                                                class="mt-0"
                                                hide-details />
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0">
                                    <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading">Aggiungi</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import apiEvacuatori from '@/js/pages/evacuatori';
import { requiredRules, matricolaRules, annoRules } from '@/js/validationRules';
const Schede = () => import('@/components/Schede');
import { generalFunction } from '../js/generalFunction.js';

export default {
    name: 'newEvacuatore',
    components: {
        Schede
    },
    data() {
        return {
            id_punto: this.$route.query.id_punto,
            id_stabilimento: this.$route.query.id_stabilimento,
            stabilimento: undefined,
            token: this.$cookies.get('token'),
            ruolo: this.$store.state.ruolo,
            anno: '',
            matricola: '',
            note: '',
            id_scheda: '',
            reparto: '',
            formValid: false,
            requiredRules: requiredRules,
            matricolaRules: matricolaRules,
            annoRules: annoRules,
            loading: false,
            dataUltimoRapporto: undefined,
            menuDataRapporto_creazione: false,
            collegaPosa: false,
        }
    },
    async created() {
        if(!this.$store.state.ruolo) {
            this.$store.state.ruolo = await generalFunction.getRuolo(this.token);
            this.ruolo = this.$store.state.ruolo;
        }

        if(
            !this.id_stabilimento &&
            this.$route.query.id_stabilimento
        ) this.id_stabilimento = this.$route.query.id_stabilimento;
        if(
            !this.id_punto &&
            this.$route.query.id_punto
        ) this.id_punto = this.$route.query.id_punto;
        this.matricolaRules = matricolaRules;
        let matricole = await apiEvacuatori.getAllMatricole(this.token);
        if(
            matricole &&
            matricole.length
        ) this.matricolaRules.push(v => (v && matricole.indexOf(v) === -1 && matricole.indexOf(v.toUpperCase()) === -1) || 'Matricola già esistente!');

        if (this.id_stabilimento) {
            let stabilimento = await apiEvacuatori.getStabilimento(this.id_stabilimento);
            if (stabilimento)
                this.stabilimento = stabilimento;
        }
    },
    methods: {
        async submit(){
            this.loading = true;
            if(
                this.$refs.form_data.validate() &&
                this.formValid
            ){
                let data = {
                    collegaPosa: this.collegaPosa,
                    note: this.note,
                    matricola: this.matricola,
                    anno: this.anno,
                    ultimoRapporto_creazione: this.dataUltimoRapporto,
                    id_punto: this.id_punto,
                    id_scheda: this.id_scheda,
                    id_stabilimento: this.id_stabilimento,
                    id_reparto: this.reparto,
                };
                let result = await apiEvacuatori.insertNew(this.token, data);
                if(result) this.$router.back();
                else alert("Errori durante l'aggiunta. Controllare l'attivita'");
            }
            this.loading = false;
        },
        async setIdScheda(id_scheda) {
            this.id_scheda = id_scheda;
        }
    }
}
</script>
